export const CALENDAR_ID = 'bo37s7qiucs82v5n5fheku7k3o@group.calendar.google.com';
export const CALENDAR_KEY = 'AIzaSyB0zvPJIwN2SxcXtMHK12SEmeqgsyuAiJ8';
let now = new Date();
let inAWeek = new Date(now);
inAWeek.setDate(now.getDate() + 7);
now.setDate(now.getDate() - 40); //todo: remove
export const CALENDAR_URL = `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?key=${CALENDAR_KEY}&orderBy=startTime&singleEvents=true&timeMin=` + now.toISOString() + '&timeMax=' + inAWeek.toISOString();

// Infos zur XML-Schnittstelle findest Du unter http://www.volleynet.at/download/volleynet_work/indoor/upload/download/oevv2/sonst/Xml-Abfragen.txt 
export const GAMES_TABLE_URL = `http://www.volleynet.at/volleynet/service/xml2.php?action=`;
export const GAMES_TABLE_LEAGUE1_ID = 23888;
export const GAMES_TABLE_LEAGUE2_ID = 23887;

//https://calendar.google.com/calendar?cid=Ym8zN3M3cWl1Y3M4MnY1bjVmaGVrdTdrM29AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ