import React from "react";
import { useDispatch } from "react-redux";
import { Link, graphql, StaticQuery } from "gatsby";

//import * as Constants from '../constants';
import DefaultLayout from "../layouts/default";
import * as actions from "../actions";
import FluidImage from "../components/FluidImage.js";
//import Breadcrumbs from '../components/Custom/Navigation/Breadcrumbs';

export default ({ location }) => {
	//  const dispatch = useDispatch();

	//   React.useEffect(() => {

	//   });

	return (
		<DefaultLayout location={location}>
			
			{/* <Breadcrumbs location={location} /> */}

			{/* cover, splash */}
			<div className="hero hero-home is-primary is-fullheight" id="hero-home">
				<div className="hero-body">
					<div className="container is-layout">
						<section className="section has-text-centered">
							<div className="columns is-tablet is-centered">
								<div className="column is-8">
									<p className="hero-logo">
										<span className="is-image-replaced">Network Ventures</span>
									</p>
									<h1>Launching rockets like others fire their barbecues.</h1>
									<p>
										Network Ventures is a venture builder cooperative based in Austria. We transform ideas into full grown businesses.
										It's great that you are here: Let us fly rockets together.
										Plus barbecue.
									</p>
									<div className="hero-scroll">
										<a href="#hero-after">
											<span className="icon icon-arrow-down"></span>
											<span className="is-sr-only">Go to content</span>
										</a>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
			
			<a id="hero-after" className=""></a>

			{/* intro, about */}
			<div className="container is-layout">
				<section className="section">
					<div className="columns is-tablet is-centered">
						<div className="column is-8">
							<h1>Network Ventures makes (your) ideas work.</h1>
							<p>
							&bdquo;Nearly every man who develops an idea works it up to the point
								where it looks impossible, and then he gets discouraged. That's
								not the place to become discouraged.&rdquo; &mdash; Thomas Edison
							</p>
						</div>
					</div>
					
					<div className="columns is-tablet mt-25">
						<div className="column">
							<p className="is-normal">
								We are a bunch of people who have ideas all the time and have
								developed some of them into great businesses. But we also know
								the feeling of get stuck and discouraged. That’s why we teamed
								up and found Network Ventures. To make ideas turn into reality.
								From scattered lone wolves with their own limited network, we
								have developed into a strong, trustworthy community of experts.
								Like an entrepreneurial wolfpack.
							</p>
						</div>
						<div className="column">
							<p className="is-normal">
								So now we are talking about rocket flying wolves who are also
								doing barbecues? Yes kind of. We are helping people with ideas
								to make them work and build them into sustainable businesses. We
								are expanding our network and develop great products the world
								didn’t know they can’t live without. But, most important, we
								have fun working together in a great team of positive people.
							</p>
						</div>
					</div>

					<div className="columns is-tablet is-centered mt-25">
						<div className="column is-8">
							<p>
								We know we have a much greater chance of being successful
								together than alone. As Thomas Edison rightly says in the quote
								at the beginning of this text: It's not the time to become
								discouraged.
							</p>
						</div>
					</div>

					<div className="columns is-tablet is-centered">
						<div className="column is-8 mt-50">
							<nav className="breadcrumb breadcrumb-forward is-small is-letter-spacing is-uppercase">
								<ul>
									<li>
										<Link to={"/about#1"}>
											About the Cooperative
											<span className="icon icon-arrow-right"></span>
										</Link>
									</li>
								</ul>
								<ul>
									<li>
										<Link to={"/about#2"}>
											About the Venture Builder
											<span className="icon icon-arrow-right"></span>
										</Link>
									</li>
								</ul>
							</nav>
						</div>
					</div>
				</section>
			</div>

			{/* reference */}
			<div className="hero hero-cover is-max-fullheight my-50">
				<StaticQuery
					query={graphql`
						query {
							allReferencesYaml(filter: {level: {lt: 2}}, sort: { fields: sorting }, limit: 1) {
								edges {
									node {
										id
										caption
										subtitle
										image
										fields {
											slug
										}
									}
								}
							}
						}
					`}
					render={({ allReferencesYaml }) => {
						//console.log("xxxallReferencesYaml", allReferencesYaml.edges[0].node);
						if (allReferencesYaml?.edges[0]?.node) {
							const pro = allReferencesYaml.edges[0].node;
							return (
								<>
									<div className="hero-bg">
										<FluidImage
											src={pro.image}
											className="image"
											alt="Name of Project"
										/>
									</div>

									<div className="hero-body">
										<div className="container is-layout">
											<section className="section">
												<h1 className="title">{pro.caption}</h1>
												<h2 className="subtitle">{pro.subtitle}</h2>
												<nav className="breadcrumb breadcrumb-forward is-small 0is-centered is-letter-spacing is-uppercase">
													<ul>
														<li>
															<Link to={"/references"}>
																References and Projects
																<span className="icon icon-arrow-right"></span>
															</Link>
														</li>
													</ul>
												</nav>
											</section>
										</div>
									</div>
								</>
							);
						}
					}}
				/>
			</div>

			{/* members and partners */}
			<div className="container is-layout">
				
				<section className="section">
					<div className="columns is-tablet is-centered">
						<div className="column is-8">
							<h1>Network Ventures are members and partners.</h1>
							<p>
								&mdash; Our wolfpack consists of members and partners. To make sure every project turns out as a success we need a lot of motivated and experienced people working together as a team. For us as a cooperative our members and partners are key to everything. So onboarding of new people is one of the most important parts for us at Network Ventures. Projects come and go, but our members and partners are here to stay.
							</p>
						</div>
					</div>

					<div className="columns is-tablet mt-25">
						<div className="column">
							<h4>Become a member.</h4>
							<p className="is-normal">
								So you wanna join the pack? Just get in touch. We love to hear more about you and your ideas. Don’t be afraid, because although we are a wolfpack we have not bitten anyone. Yet.
							</p>

							<nav className="breadcrumb breadcrumb-forward is-small is-letter-spacing is-uppercase mt-50">
								<ul>
									<li>
										<Link to={"/members"}>
											Member<span className="icon icon-arrow-right"></span>
										</Link>
									</li>
								</ul>
							</nav>

						</div>
						<div className="column">
							<h4>Become a partner.</h4>
							<p className="is-normal">
								You wanna have access to fresh ideas and a motivated group of passionate entrepreneurs. Feel free to get in touch and let’s start some rockets together. Barbecue invitation included.
							</p>

							<nav className="breadcrumb breadcrumb-forward is-small is-letter-spacing is-uppercase mt-50">
								<ul>
									<li>
										<Link to={"/partners"}>
											Partner<span className="icon icon-arrow-right"></span>
										</Link>
									</li>
								</ul>
							</nav>

						</div>
					</div>
				</section>

			</div>
			
			{/* project, submit */}
			<div className="hero hero-cta is-primary is-fullheight mt-50">
				<div className="hero-body">
					<div className="container is-layout">
						
						<section className="section has-text-centered">
							<div className="columns is-tablet is-centered">
								<div className="column is-8">
									<h1>Hello, I've got a project.</h1>

									<p className="mb-50">
										You already have an idea and wanna move out from the living room or garage but don’t know how or you need some special expertise? Hit the button below and let us see what you got.
									</p>

									<Link
										to={"/submit"}
										className="button is-primary is-inverted is-letter-spacing is-uppercase mb-50"
									>
										Submit your project
									</Link>

									<p className="is-normal mb-50">
										We assemble the right experts to screen your project and let you know if you have the next unicorn on the leash or if you're only riding a pony yet and we need to spark it up a little. We can help with all steps of development from business plan, design, prototyping, marketing strategy to market launch, social media marketing, advertising through to an exit (if you fancy this step).
									</p>

									<p>
										Is this the beginning of a beautiful friendship? We are quite sure it is.
									</p>

									<nav className="breadcrumb breadcrumb-forward is-small is-centered is-letter-spacing is-uppercase mt-50">
										<ul>
											<li>
												<Link to={"/submit"}>
													How to Submit
													<span className="icon icon-arrow-right"></span>
												</Link>
											</li>
										</ul>
									</nav>
								</div>
							</div>
						</section>

					</div>
				</div>
			</div>
		
		</DefaultLayout>
	);
};
